import * as React from "react";
import {GatsbyImage, getImage, withArtDirection} from "gatsby-plugin-image"
import {graphql, HeadProps, PageProps} from "gatsby"
import Cookies from "universal-cookie";
import loadable from '@loadable/component'
import {useMediaQuery} from "usehooks-ts";

import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import Block8Text4Image from "../components/blocks/block-8text-4image";
import Section from "../components/section";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import MapSingle from "../components/map/single-fixed";
import ArticlesGridShort from "../components/articles-grid-short";
import AdvisorRandom from "../components/advisor-random";
import LocationsTabs from "../components/tab/locations-tabs";
import FuneralPackageGrid from "../components/funeral-package-grid";
import TemplateHead from "../components/template-head";
import FuneralDirectorsNearest from "../components/funeral-directors-nearby";
import AdvisorSlider from "../components/slider/advisor-slider";
import BenefitsSlider from "../components/slider/benefits-slider";
import ReadMore from "../components/read-more";
import Accordion from "../components/accordion";

const BlockMap = loadable(() => import('../components/blocks/block-map'))

import "../styles/page-city.scss";
import SafeBlock from "../components/safe-block";

type DataProps = {
  strapiCity: any,
  advisorBg: any,
  funeralHomesBg: any,
  locationsBg: any,
  cemeteries: any,
  churches: any,
  crematoriums: any,
  advisorList: any,
  singleReviewBg: any
  defaultHeroDesktopBg: any,
  defaultHeroTabletBg: any,
  defaultHeroMobileBg: any,
}

const cookies = new Cookies();

export default function CityPage({
                                   data: {
                                     strapiCity,
                                     churches,
                                     cemeteries,
                                     crematoriums,
                                     advisorBg,
                                     locationsBg,
                                     singleReviewBg,
                                     funeralHomesBg,
                                     defaultHeroDesktopBg,
                                     defaultHeroTabletBg,
                                     defaultHeroMobileBg,
                                   }
                                 }: PageProps<DataProps>) {
  const city = strapiCity;

  const cookieCity = cookies.get("fenix4city");
  if (cookieCity !== city.cityUrl) {
    cookies.remove("fenix4city", {path: '/'});
    cookies.remove("fenix4hub", {path: '/'});
    let d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expiresTime = d;
    cookies.set("fenix4city", city.cityUrl, {
      expires: expiresTime,
      path: "/",
    });
    cookies.set("fenix4label", city.title, {
      expires: expiresTime,
      path: "/",
    });
    cookies.set("fenix4hub", city.hub?.urlPart, {
      expires: expiresTime,
      path: "/",
    });
  }
  const imagesHero = withArtDirection(getImage(city.image?.hero?.localFile ? city.image?.hero?.localFile : defaultHeroDesktopBg.edges[0].node.localFile), [
    {
      media: "(max-width: 719px)",
      image: getImage(city.image?.small2x?.localFile ? city.image?.small2x?.localFile : defaultHeroTabletBg.edges[0].node.localFile),
    },
    {
      media: "(max-width: 1199px)",
      image: getImage(city.image?.middle?.localFile ? city.image?.middle?.localFile : defaultHeroMobileBg.edges[0].node.localFile),
    },
  ])
  const matches = useMediaQuery('(min-width: 768px)')

  const pageTextWelcome = (
    city.textSeo?.textWelcome
  );

  const pageTextMap = (
    <div>
      <p>We are experts at supporting families to make funeral arrangements, as well as other legal and practical
        arrangements, online and over the telephone.</p>
      <p>However, many of our customers value meeting in person. We can come to you, or meet at one of our private
        meeting rooms.</p>
      <p>Please note that <em><b>you must always book an appointment in advance</b></em>. You can easily book an
        appointment by calling us or sending an e-mail.</p>
    </div>
  );
  const benefits = [];
  benefits.push({
    icon: {url: "/images/icons/icon_hugs-deepblue.svg"},
    title: city.textSeo?.uspTitle1,
    textMain: city.textSeo?.uspText1
  }, {
    icon: {url: "/images/icons/icon_testamente-deepblue.svg"},
    title: city.textSeo?.uspTitle2,
    textMain: city.textSeo?.uspText2
  }, {
    icon: {url: "/images/icons/icon-flowers__deepblue.svg"},
    title: city.textSeo?.uspTitle3,
    textMain: city.textSeo?.uspText3
  });

  const advisorList = city.advisorList.filter(function (obj) {
    return obj.advisorCity !== null;
  });

  return (
    <Layout as="city" hubSlug={city.hub.urlPart}>
      <Hero
        imageOptimized={imagesHero}
        altImages={city.seo?.metaTitle}
        sectionClassName="hero-section hero-section--city pl-4 pr-4 boo"
        bgImageClassName="hero-image hero-image--city"
        attribute={city.image?.hero?.name}
        contentClassName="hero-content hero-content--city"
        breadcrumb={[
          {name: "Fenix Home", slug: "/"},
          {
            name: city.heading?.main
              ? city.heading?.main
              : "Fenix Funeral Directors in " + city.title,
            slug: "/funeral-directors/" + city.cityUrl + "/",
          },
        ]}
      >
        <HeroContentHeading
          headingTitle={
            city.heading?.main
              ? city.heading?.main
              : "Fenix Funeral Directors in " + city.title
          }
          headingContent={"Trusted and affordable – from £ 995"}
          headingLocation={city.office && city.office?.fullAddress ? city.office?.fullAddress + ", " + city.office?.postcode : ""}
          advisorData={city.advisorList[0]?.advisorCity}
        />
        <Block8Text4Image
          h2={
            city.textSeo?.headingWelcome
              ? city.textSeo?.headingWelcome
              : "Our funeral homes in " + city.title
          }
          textClassName="text-20"
          textSeo={pageTextWelcome}
          bgImage={"/images/fenix-funeral-homes-in-city@2x.jpg"}
          bgImageClass="d-none d-md-block"
          rightImageBg={funeralHomesBg?.edges}
          linkText="Read more"
        />
      </Hero>
      <Section
        sectionClass="bg-deepblue pl-4 pr-4 section-container section-container--advisors pt-5"
      >
        <AdvisorSlider
          advisorList={advisorList}
          advisorTitle={city.textSeo?.advisorsSectionTitle}
        />
      </Section>
      <BenefitsSlider
        benefitSectionTitle={city.textSeo?.whyFenixLocalTitle}
        benefitSectionText={city.textSeo?.whyFenixLocalText}
        benefitSlider={benefits}
      />
      <Section>
        <div className="block-wrapper pt-5 mt-5">
          <div className="row no-gutters flex-column-reverse flex-lg-row reverse-row">
            <div
              className="single-review-col col-12 col-lg-8 bg-white col-top-left pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5">
              <h2
                className="mb-4 text-deepblue">“{city.review ? city.review : "Excellent service. Polite, caring and professional. \\n\\nTook all the pressure off at a difficult time."}” </h2>
              <p className="font-weight-bold">– {city.reviewAuthor ? city.reviewAuthor : "Elizabeth"} </p>
            </div>
            <div className="col-12 col-lg-4 col-bgImage col-bottom-right">
              {singleReviewBg &&
                <GatsbyImage
                  className="col-bgImage__bg w-100"
                  image={singleReviewBg.edges[0]?.node?.childImageSharp?.gatsbyImageData}
                  alt="Background image Fenix Funeral Directors"
                  loading="lazy"/>}
            </div>
          </div>
        </div>
      </Section>
      <Section
        sectionClass={"section-container package-section pl-4 pr-4"}
      >
        <FuneralPackageGrid
          packagesTitle={city.textSeo?.cityPackagesTitle}
          packagesText={city.textSeo?.cityPackagesText}
          cremationTopText={city.textSeo?.cityCremationTop}
          cremationBottomText={city.textSeo?.cityCremationBottom}
          crematoriumTopText={city.textSeo?.cityCrematoriumTop}
          crematoriumBottomText={city.textSeo?.cityCrematoriumBottom}
          burialTopText={city.textSeo?.cityBurialTop}
          burialBottomText={city.textSeo?.cityBurialBottom}
          bespokeTopText={city.textSeo?.cityBespokeTop}
          bespokeBottomText={city.textSeo?.cityBespokeBottom}
          hubSlug={city.hub.urlPart}
        />
      </Section>
      <Section
        sectionClass={"section-container safe-section pl-4 pr-4 pt-lg-4"}
      >
        <SafeBlock />
      </Section>
      <LocationsTabs
        locationsImageBg={locationsBg?.edges}
        city={city}
        cemeteries={cemeteries}
        crematoriums={crematoriums}
        churches={churches}
        lat={city.geo?.latitude}
        lng={city.geo?.longitude}
      />
      {city.textSeo?.cityAdvisorsTitle && <Section>
        <div className="block-wrapper pt-5 mt-5">
          <div className="row no-gutters flex-column-reverse flex-lg-row reverse-row">
            <div className="col-12 col-lg-8 bg-white col-top-left pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5">
              <h2 className="mb-4 text-deepblue">{city.textSeo?.cityAdvisorsTitle}</h2>
              <ReadMore
                text={city.textSeo?.cityAdvisorsText}
                maxLength={matches ? 5 : 2}
                className="col-content text-deepblue text-18"
              />
            </div>
            <div className="col-12 col-lg-4 col-bottom-right">
              {singleReviewBg &&
                <GatsbyImage
                  className="col-bgImage__bg w-100"
                  image={city.image?.middle?.localFile?.childImageSharp?.gatsbyImageData}
                  alt="Background image Fenix Funeral Directors"
                  loading="lazy"/>}
            </div>
          </div>
        </div>
      </Section>}
      <AdvisorRandom
        advisorImageBg={advisorBg?.edges}
        advisorData={city.advisorList[0]?.advisorCity}
        sectionClassName="advisor-book advisor-book--section advisor-book--city pr-4 pl-4 pb-5"
      />
      <Section
        sectionClass={"mt-5 pt-5 pl-4 pr-4 section-container section-container--map"}
      >
        <BlockMap
          rowClass="row no-gutters flex-column-reverse flex-lg-row-reverse"
          col1Class="col-12 col-lg-4 col-bottom-right bg-white pl-3 pl-md-5 pr-3 pr-md-5 pt-4 pt-md-5 pb-4 pb-md-5"
          col2Class="col-12 col-lg-8 col-top-left"
          h2={"Meeting Rooms — by appointments only"}
          text={pageTextMap}
          headingTitle={
            city.heading?.main
              ? city.heading?.main
              : "Fenix Funeral Directors in " + city.title
          }
          office={city.office && city.office?.fullAddress ? city.office?.fullAddress + ", " + city.office?.postcode : ""}
          map={
            <MapSingle
              key={`map-single-key`}
              lat={city.geo?.latitude}
              lng={city.geo?.longitude}
              city={city.title}
              heading={city.heading?.main}
              company={city.office?.corporateChain}
              address={city.office?.fullAddress}
              postcode={city.office?.postcode}
            />
          }
        />
      </Section>
      <ArticlesGridShort
        gridClass={"articles-section articles-section--city pr-4 pl-4 pb-5"}
        sectionTitle={city.textSeo?.articlesTitle}
        sectionText={city.textSeo?.articlesText}
      />
      <Section
        sectionClass={"pt-3 pb-2 pb-lg-5 pl-4 pr-4 section-container bg-white"}>
        <div className="block-wrapper pt-lg-5 mt-lg-5">
          <h2 className="mb-3 mb-lg-5">Frequently asked questions</h2>
          <Accordion
            items={city.faqData}
          />
        </div>
      </Section>
      <FuneralDirectorsNearest
        cityId={city?.strapi_id}
        lat={city.geo?.latitude}
        lng={city.geo?.longitude}
      />
      <TrustpilotCarousel/>
    </Layout>
  );
}

export function Head({data: {strapiCity, defaultHeroDesktopBg}}: HeadProps<DataProps>) {
  const city = strapiCity;
  return (
    <TemplateHead
      title={city.seo.metaTitle}
      shortlink={city.seo.canonicalURL}
      alternate={city.seo.canonicalURL}
      canonical={city.seo.canonicalURL}
      description={city.seo.metaDescription}
      og={{
        url: city.seo.canonicalURL,
        title: city.seo.metaTitle,
        type: "business.business",
        description: city.seo?.metaDescription,
        image: city.image?.hero?.url ? city.image?.hero?.url : defaultHeroDesktopBg.edges[0].node.url,
        imageWidth: 600,
        imageHeight: 315
      }}
      business={{
        contact_data: {
          street_address: city.office?.fullAddress ? city.office.fullAddress : '',
          locality: city.title,
          region: city.county,
          postal_code: city.office?.postcode ? city.office?.postcode : '',
          country_name: "United Kingdom"
        }
      }}
      ldjson={
        `{
          "@context": "https://schema.org",
          "@id": "https://fenixfuneral.co.uk/#webpage",
          "url": "${city.seo.canonicalURL}",
          "@type": "LocalBusiness",
          "name": "Fenix Funeral Directors in ${city.title}",
          "priceRange": "£995 - £2280",
          "telephone": "0808 164 9040",
          "image":"${city.image?.hero?.url ? city.image?.hero?.url : defaultHeroDesktopBg.edges[0].node.url}",
          "aggregateRating":{
              "@type":"AggregateRating",
              "ratingValue":"4.8",
              "reviewCount":"152"
          },
          "address":{
              "@type":"PostalAddress",
              "streetAddress":"${city.office?.fullAddress ? city.office?.fullAddress : ''}",
              "addressLocality":"${city.title}",
              "addressRegion":"${city.hub?.title}",
              "postalCode":"${city.office?.postcode ? city.office?.postcode : ''}",
              "addressCountry":{
                  "@type":"Country",
                  "name":"United Kingdom"
              }
          }
          }`
      }
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement":
          [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Fenix Funeral Directors in ${city.title}","item": "${city.seo.canonicalURL}"
            }
          ]
          }`
      }
      ldjsonfaq={`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": 
          [
            {
              "@type": "Question",
              "name": "Can we have a viewing or visitation before the funeral service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, you are welcome to view your loved one at chapels of rest at select locations, we only ask that you schedule an appointment with your appointed Funeral Advisor. As viewing is often a personal choice for each family and not something that is required, we do not include this with the main package which only includes essential elements of a funeral."
              }
            },{
              "@type": "Question",
              "name": "What is the process for planning a funeral?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Once Fenix receives your enquiry, you will be placed with a personal Funeral Advisor who is responsible for completing all necessary paperwork and liaising with local authorities as required. Your expert Funeral Advisor will also help you decide on the right elements (coffins, flower tributes, and more) for your funeral."
              }
            },{
              "@type": "Question",
              "name": "What are the available options for caskets and urns?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our casket and urn selection can be viewed on our website. Please note that our website showcases only the most popular of our product selection, we offer many more options, if there is something in particular you have in mind discuss it with your Funeral Advisor."
              }
            },{
              "@type": "Question",
              "name": "Can you coordinate with our chosen religious leader or celebrant for the service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Can you coordinate with our chosen religious leader or celebrant for the service?"
              }
            },{
              "@type": "Question",
              "name": "Can you recommend local florists for funeral flowers and arrangements?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We work with florists all over the UK, we can even create custom funeral tributes. For more information on our selection of floral arrangements and custom tributes, speak to your Funeral Advisor."
              }
            },{
              "@type": "Question",
              "name": "Can you assist with arranging a reception or gathering after the funeral service?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, for a small fee we can help you arrange wakes, memorials, and/or receptions before or after the funeral service."
              }
            },{
              "@type": "Question",
              "name": "When my loved one is brought into care, where will they be resting?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "When we bring your loved one into care, they will be resting in one of our care facilities. These facilities are specially designed to be temperature and moisture controlled."
              }
            }
          ]
          }`
      }
    />
  );
}

export const pageQuery = graphql`
    fragment imageDataSeoHero on STRAPI__MEDIA {
        url
        localFile {
            childImageSharp {
                gatsbyImageData(
                    placeholder: NONE
                    outputPixelDensities: 1
                )
            }
        }
    }
    fragment imageDataSeoBg on FileConnection {
        edges {
            node {
                name
                childImageSharp {
                    gatsbyImageData(
                        placeholder: NONE
                        outputPixelDensities: 1
                    )
                }
            }
        }
    }
    fragment locationsData on STRAPI_LOCATIONConnection {
        nodes {
            strapi_id
            title
            slug
            type {
                slug
                title
            }
            hub {
                title
                urlPart
            }
            city {
                title
                cityUrl
            }
            images {
                awsThumbnail
                awsImage
            }
            geo {
                longitude
                latitude
            }
        }
    }
    query ($slug: String, $hubSlug: String) {
        strapiCity(cityUrl: { eq: $slug }) {
            cityUrl
            title
            text {
                headingPlan
                textPlan
                textSpecialService
                textWelcome
                headingWelcome
                textTabChurch
                textTabCemetery
            }
            review
            reviewAuthor
            textSeo {
                whyFenixLocalTitle
                whyFenixLocalText
                uspTitle3
                uspTitle2
                uspTitle1
                uspText3
                uspText2
                uspText1
                titleTabCrematorium
                textTabCrematorium
                titleTabCemetery
                textTabCemetery
                titleTabChurch
                textTabChurch
                textWelcome
                advisorsSectionTitle
                articlesText
                articlesTitle
                cityAdvisorsText
                cityAdvisorsTitle
                cityBespokeBottom
                cityBurialBottom
                cityBespokeTop
                cityBurialTop
                cityCremationBottom
                cityCremationTop
                cityCrematoriumBottom
                cityCrematoriumTop
                cityPackagesText
                cityPackagesTitle
                headingWelcome
            }
            seo {
                metaTitle
                metaDescription
                canonicalURL
            }
            geo {
                latitude
                longitude
            }
            faqData {
                question
                answer
            }
            office {
                corporateChain
                fullAddress
                postcode
            }
            hub {
                id
                title
                urlPart
            }
            isHub
            image {
                middle {
                    ...imageDataSeoHero
                }
                hero {
                    alternativeText
                    name
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1920
                                outputPixelDensities: 1
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                small2x {
                    ...imageDataSeoHero
                }
            }
            advisorList {
                advisorCity {
                    url
                    fullname
                    description {
                        data {
                            description
                        }
                    }
                    photo2x {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: NONE,
                                    outputPixelDensities: 1
                                )
                            }
                        }
                    }
                }
            }
        }
        defaultHeroDesktopBg: allStrapiMedia(filter: {url: {eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_f4f23a349f.jpg"}}) {
            edges {
                node {
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
            }
        }
        defaultHeroTabletBg: allStrapiMedia(filter: {url: {eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_3b9130ae5a.jpg"}}) {
            edges {
                node {
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
            }
        }
        defaultHeroMobileBg: allStrapiMedia(filter: {url: {eq: "https://nextpwcms.s3.eu-north-1.amazonaws.com/city_placeholder_2x_4fc0811750.jpg"}}) {
            edges {
                node {
                    url
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: NONE,
                                outputPixelDensities: 1
                            )
                        }
                    }
                }
            }
        }
        advisorBg: allFile(filter: {name: {in: ["AdvisorBackgroundImage"]}}) {
            ...imageDataSeoBg
        }
        funeralHomesBg: allFile(filter: {name: {in: ["fenix-funeral-homes-in-city@2x"]}}) {
            ...imageDataSeoBg
        }
        singleReviewBg: allFile(filter: {name: {in: ["hero-about-mob"]}}) {
            ...imageDataSeoBg
        }
        locationsBg: allFile(filter: {name: {in: ["BottomBackgroundImage-Web"]}}) {
            ...imageDataSeoBg
        }
        churches: allStrapiLocation(
            filter: {type: {slug: {in: ["churches"]}}, hub: {urlPart: {in: [$hubSlug]}}}
            sort: {fields: title, order: ASC}) {
            ...locationsData
        }
        crematoriums: allStrapiLocation(
            filter: {type: {slug: {in: ["crematoriums"]}}, hub: {urlPart: {in: [$hubSlug]}}}
            sort: {fields: title, order: ASC}) {
            ...locationsData
        }
        cemeteries: allStrapiLocation(
            filter: {type: {slug: {in: ["cemeteries"]}}, hub: {urlPart: {eq: $hubSlug}}}
            sort: {fields: title, order: ASC}) {
            ...locationsData
        }
    }
`;